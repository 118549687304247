@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Alexandria, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  background-image: url("../public/assets/grainy_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.ReactCollapse--collapse {
  transition: height 200ms;
}
